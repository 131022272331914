@import './variables/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #FAF7F7;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #C4C4C4;
  }
}

html, body {
  height: 100% !important;
}

html .blank-page .content.app-content .content-wrapper {
  overflow: unset;
}

.text-justify {
  text-align: justify;
}

html .blank-page .content.app-content .content-body {
  height: 100vh;
}

.pagination {
  margin: 30px auto 0;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 100px;

  .pagination-item {
    font-size: 18px;
    padding: 10px 12px;
    border-radius: 5px;
    background: #FFFFFF;
    color: #124D81;
    cursor: pointer;
    box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);

    &.active {
      background: #124D81;
      color: #FFFFFF;

    }
  }
}


.page-comments {
  overflow-y: scroll !important;
  height: 570px !important;
}


#root {
  .wrapper {
    height: 0 !important;
  }
}

.loading-row {
  height: 32px;
  display: grid;
  grid-template-columns: auto auto;
  max-width: 250px;

  .loading-report {
    height: 32px;
    grid-column: 1/-1;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 16px;
    color: #FFFFFF;
    background: #DE8A3E;
  }
}

.report-comment {
  width: 100%;
  max-width: 400px;
  position: absolute;
  padding: 14px 30px;
  border-radius: 6px;

  background: #FFFFFF;

  display: flex;
  flex-direction: column;
  align-items: center;

  .comment-title {
    font-size: 18px;
    color: #898B9D;
    margin-bottom: 10px;
  }

  textarea {
    height: 225px;
  }

  .buttons {
    button {
      width: 100px;
    }

    button:first-child {
      margin-right: 10px;
    }
  }
}

.table-icons {
  display: flex;
  justify-content: center;


  div:last-child {
    svg {
      fill: #898B9D;
    }
  }
}

.mobile-menu {
  margin-left: 15px;

  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.08);

    svg {
      margin-top: 8px;
    }
  }
}

.mobile-header-menu {
  display: none;
  align-items: center;
  justify-content: space-between;

  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.07);
  }

  .content-header__langs {
    display: flex;
    list-style: none;
    margin: 0;

    .lang {
      margin-right: 12px;
      font-weight: 400;
      font-style: normal;
      font-size: 20px !important;
      transition: all ease-in .3s;


      a.active {
        color: #32323B;
        transition: all ease-in .3s;
      }

      a:hover {
        text-decoration: underline;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.post {
  //height: 100% !important;
  overflow: hidden;

  .news-content {
    height: 100% !important;
    padding: 0 !important;
    overflow: scroll;

    .news-content__banner {
      padding: 0 !important;
    }
  }
}

.container-xxl {
  height: 100%;
  display: flex;
  justify-content: center;
}

.content-wrapper.container-xxl {
  // padding: 0 !important;
}

#react-select-2-listbox {
  overflow: visible !important;
  position: absolute !important;
  z-index: 9999999 !important;
}

#react-select-3-listbox {
  //z-index: 9999;
  //overflow: visible !important;
  //position: absolute !important;
}

.logout {
  width: 86px;
  text-align: center;
  font-size: 18px;
  background: #DE8A3E;
  color: #fff;
  padding: 9px 11px 6px 11px;
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  right: 40px;
  top: -41px;
  z-index: 9999;
  cursor: pointer;
}

.mobile-logout {
  position: absolute;
  top: 550px;
  left: 15px;
  border-radius: 2px;
  padding: 12px 22px;
  background: none;
  color: #DE8A3E !important;
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
}

.customer-support {
  padding: 0.8rem 1.4rem;
  position: relative;
  flex: 1 1 auto;
}

.float-menu {
  position: absolute;
  top: 420px;
  left: -1px;
  display: flex;
  flex-direction: column;

  &__item {
    border-color: #EFEFEF !important;
    border-radius: 0px 11px 11px 0px;
    border-left: none;
    margin-bottom: 18px;

    svg {
      stroke-width: 2;

      path {
        fill: #898B9D;
      }
    }

    &.active {
      background: #124C7F !important;

      svg {
        stroke-width: 2;

        path {
          fill: #fff;
        }
      }
    }

  }
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.w-100 {
  max-width: 100% !important;
}

.disabled {
  pointer-events: none;
  background: #e6e6e6;
}

button.disabled {

  background: #C4C4C4 !important;
  border: none;
  opacity: 1 !important;
}

.user-name-mb {
  display: none;
}

.item-wrapper {
  position: relative;

  .icon-reports {
    position: absolute;
    left: -36px;
    top: 16px;
  }

}

.popover-close {
  position: relative;

  svg {
    position: absolute !important;
    right: 18px;
    top: 2px;
    cursor: pointer;
  }
}

.modal-alert {
  padding-top: 46px;
  padding-bottom: 16px;
  padding-right: 52px;
  padding-left: 52px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .modal-close {
    position: absolute;
    padding: 15px;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  &__icon {

  }

  &__title {
    h4 {
      font-size: 32px;
      font-weight: bold;
      color: #000103;
    }
  }

  button {
    font-size: 24px;
  }

  button.disabled {
    font-size: 24px;
  }
}

.back-button {
  cursor: pointer;
  position: absolute;
  top: 56px;
}

.nav {
  .dropdown-user {
    .nav-link {
      .content-header__langs {
        display: flex;
        list-style: none;
        margin-left: auto;

        .lang {
          margin-right: 12px;
          font-weight: 400;
          font-style: normal;
          font-size: 20px !important;
          transition: all ease-in .3s;

          a {
            color: #898B9D;
          }


          a.active {
            color: #32323B;
            transition: all ease-in .3s;
          }

          a:hover {
            text-decoration: underline;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}


.horizontal-menu .navbar.header-navbar .navbar-container {
  max-width: 1236px;
  margin: 0 auto;
  padding: 0.8rem 0rem !important;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > a {
  margin-right: 1.6rem !important;
}

.horizontal-menu .horizontal-menu-wrapper .header-navbar .navbar-container {
  max-width: 1236px !important;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li {
  padding: 0 !important;
}

.horizontal-menu .horizontal-menu-wrapper .header-navbar .navbar-container {
  padding: 0 8rem !important;
  display: flex !important;
  justify-content: center !important;
}

.horizontal-layout.navbar-sticky .horizontal-menu-wrapper .navbar-horizontal.header-navbar.fixed-top {
  background-color: unset !important;
  box-shadow: none !important;
}

.dark-layout .horizontal-layout.navbar-sticky .horizontal-menu-wrapper .navbar-horizontal.header-navbar.fixed-top {
  background-color: unset !important;
  box-shadow: none !important;
}

.horizontal-layout.navbar-sticky .header-navbar {
  background-color: unset !important;
  box-shadow: none !important;
}


.horizontal-layout .horizontal-menu-wrapper #main-menu-navigation .nav-item:not(.dropdown):not(.dropdown-submenu) a.nav-link.active {
  animation-duration: .6s;
  animation-name: slidein;
}

.vertical-overlay-menu.menu-hide.vertical-overlay-menu.menu-open > div.main-menu.menu-fixed.menu-accordion.menu-shadow.menu-light > div.scrollbar-container.main-menu-content.ps > ul > li > a {
  display: flex;
  align-items: flex-end;
}


#main-menu-navigation > li > a {
  width: 180px !important;
}

@keyframes slidein {
  from {
    margin-top: 10%;
    height: 70px;
  }

  to {
    margin-top: 0%;
    height: 70px;
  }
}

.horizontal-layout .horizontal-menu-wrapper #main-menu-navigation .nav-item:not(.dropdown):not(.dropdown-submenu) a.nav-link {
  border-radius: 20px 20px 0px 0px;
  background-color: #FFFFFF;
  max-width: 162px;
  padding: 12px 14px;
  font-size: 16px;
  line-height: 1;
  box-shadow: 3px 3px 52px 6px rgba(0, 0, 0, 0.1) !important;
  height: 56px;
  //transition: height ease-in-out .2s !important;

  svg {
    path {
      fill: #898B9D;
    }
  }
}

.horizontal-layout .horizontal-menu-wrapper #main-menu-navigation .nav-item:not(.dropdown):not(.dropdown-submenu) a.nav-link.active {
  border-radius: 20px 20px 0px 0px;
  background-color: #124D81;
  height: 60px;
  top: -5px;


  svg {
    path {
      fill: #FFFFFF;
    }
  }
}

.horizontal-layout .horizontal-menu-wrapper .header-navbar.navbar-horizontal ul#main-menu-navigation > li:hover:not(.active) > a {
  background: transparent;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li svg {
  //height: 30px !important;
  //width: 42px !important;
  //width: -webkit-fill-available !important;
  //max-width: 42px !important;
}

.horizontal-layout.navbar-sticky .app-content {
  padding: 0 !important;
  overflow: visible !important;
}


.img-fluid {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 10px 0 0 10px;
}

.swal2-container.swal2-center > .swal2-popup {
  border-radius: 15px;
}

.swal2-icon {
  border-color: transparent;
  bottom: -80px !important;
}

.swal2-actions {
  width: 80% !important;
}

.checkmark {
  position: absolute;
  transform: translate(-50%);
  left: 50%;
  top: 60px;
}

.cswitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 14px;
  max-width: 210px;

  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  &-label {
    width: 120px;
  }
}

.login-list-mobile {
  display: flex;
  padding: 12px 20px;

  div {
    font-size: 18px;
    font-weight: 500;
  }

  &__date {
    width: 160px;
    text-align: center;
    margin-right: 40px
  }

  &__ip {
    width: 160px;
    text-align: center;
  }
}

.payment-list-mobile {
  display: flex;
  padding: 12px 20px;

  div {
    font-size: 18px;
    font-weight: 500;
  }

  &__date {
    width: 100px;
    text-align: center;
  }

  &__amount {
    width: 160px;
    text-align: center;
    margin-right: 20px;
  }

  &__service {
    width: 70px;
    text-align: center;
  }
}

//.form-switch .form-check-input:not(:checked) {
//  background-color: #FFFFFF !important;
//  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1IDcuNUMxNSAxMS42NDIxIDExLjY0MjEgMTUgNy41IDE1QzMuMzU3ODYgMTUgMCAxMS42NDIxIDAgNy41QzAgMy4zNTc4NiAzLjM1Nzg2IDAgNy41IDBDMTEuNjQyMSAwIDE1IDMuMzU3ODYgMTUgNy41WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTE0IDcuNUMxNCAxMS4wODk5IDExLjA4OTkgMTQgNy41IDE0QzMuOTEwMTUgMTQgMSAxMS4wODk5IDEgNy41QzEgMy45MTAxNSAzLjkxMDE1IDEgNy41IDFDMTEuMDg5OSAxIDE0IDMuOTEwMTUgMTQgNy41WiIgZmlsbD0iI0M0QzRDNCIvPgo8L3N2Zz4K) !important;
//}
//
//.form-switch .form-check-input {
//  background-color: #FFFFFF !important;
//}
//
//.form-switch .form-check-input:checked {
//  background-color: #FFFFFF !important;
//  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1IDcuNUMxNSAxMS42NDIxIDExLjY0MjEgMTUgNy41IDE1QzMuMzU3ODYgMTUgMCAxMS42NDIxIDAgNy41QzAgMy4zNTc4NiAzLjM1Nzg2IDAgNy41IDBDMTEuNjQyMSAwIDE1IDMuMzU3ODYgMTUgNy41WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTE0IDcuNUMxNCAxMS4wODk5IDExLjA4OTkgMTQgNy41IDE0QzMuOTEwMTUgMTQgMSAxMS4wODk5IDEgNy41QzEgMy45MTAxNSAzLjkxMDE1IDEgNy41IDFDMTEuMDg5OSAxIDE0IDMuOTEwMTUgMTQgNy41WiIgZmlsbD0iIzEyNEQ4MSIvPgo8L3N2Zz4K) !important;
//}

.nav-link-style {
  position: relative;
  padding: 0.358rem 0.9rem;

  .bookmark {
    position: absolute;
    width: 50px !important;
    height: 60px !important;
    background: #FFFFFF !important;
    border-radius: 0px 0px 11px 11px;
    z-index: -1;
    left: 0;
    top: -20px;
    box-shadow: 3px 3px 52px 6px rgb(0 0 0 / 10%);


  }
}

.select-absolute {
  //position: absolute !important;
  //width: 260px;
  //top: -7.5px;
}

.contacts-mobile {
  div {

    &:first-child {
      margin-bottom: 35px;
    }

    .contacts-item {
      display: flex;
      margin-bottom: 26px;

      svg {
        margin-right: 18px;
      }
    }
  }
}


.faqs-mobile {
  overflow: hidden;


  .accordion {
    height: 600px;
    //overflow-y: hidden;


    &-item {
      border: none;
      //border-radius: 10px !important;
      //box-shadow: 3px 3px 29px 6px rgba(0, 0, 0, 0.08) !important;

      h2 {
        button {
          padding-right: 0px;
          padding-left: 0px;

          font-size: 18px !important;
          font-weight: 400 !important;
          color: #898B9D !important;

          .accordion-label {
            max-width: 306px !important;
          }
        }
      }

      .accordion-header {
        border-radius: 10px !important;
        position: relative;
        padding-left: 25px;

        //&:before {
        //  position: absolute;
        //  content: '';
        //  width: 7px;
        //  height: 7px;
        //  border-radius: 10px;
        //  background: #000 !important;
        //  z-index: 4 !important;
        //  top: 20px;
        //  left: 20px;
        //}
      }


      .accordion-button::after {
        background: none;

      }


      .accordion-collapse {
        .accordion-body {
          max-width: 340px;
          text-align: justify !important;
          padding-left: 50px;
          color: #898B9D;
        }
      }
    }
  }
}

.note {


  &-wrapper {
    display: flex;
    justify-content: center;
    //padding-top: calc(var(--vh, 0.05vh) * 100);
    margin: auto 0;
    position: fixed;
    left: 50%;
    top: 68px;
    transform: translateX(-50%);
    width: 1236px;
    z-index: 999;


    .note-inner {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 803px;
      box-shadow: 3px 3px 52px 6px rgba(0, 0, 0, 0.07);
      border-radius: 10px;
      z-index: 2;
      // max-height: 803px;


      &__dots {

        position: fixed;
        margin-left: 575px;
        margin-top: 10px;
        z-index: 3;


        .dot {
          display: flex;
          margin-top: 10px;
          position: relative;

          .rect {
            width: 16px;
            height: 16px;
            background: #FFFFFF;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.10);
            border-radius: 2px;
          }

          .rect:first-child {
            margin-right: 28px;
          }

          .line {
            position: absolute;
            left: 4px;
            top: 4px;
            width: 51px;
            height: 7px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), inset 0px -4px 4px rgba(0, 0, 0, 0.1), inset 0px 4px 4px rgba(255, 248, 187, 0.10);
            border-radius: 4px;
          }
        }
      }

      &__banner {
        display: flex;
        width: 608px;
        border-radius: 10px 0 0 10px;


      }


      &__content {
        position: relative;

        width: 628px;
        max-width: 628px;
        display: flex;
        padding: 43px 68px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: initial;

        background-color: #fff;
        box-shadow: 3px 3px 52px 6px rgba(0, 0, 0, 0.07);
        border-radius: 0 10px 10px 0;


        .content-header {
          width: 100%;
          display: flex;

          &__langs {
            display: flex;
            list-style: none;
            margin-left: auto;

            .lang {
              margin-right: 12px;
              font-weight: 400;
              font-style: normal;
              font-size: 20px;
              transition: all ease-in .3s;

              a {
                color: #ADAEBB;
              }

              a.active {
                color: #32323B;
                transition: all ease-in .3s;
              }

              a:hover {
                text-decoration: underline;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .content-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .content-logo {
            display: flex;
            justify-content: center;
          }

          .content-headings {
            margin-top: 6rem;
            text-align: center;

            h2 {
              color: #898B9D;
            }

            .auth-content {

              text-align: center;
              font-weight: 300;
              font-size: 14px;
              color: #898B9D;

              a {
                color: $primary;

              }

              a:hover {
                text-decoration: underline;
              }
            }
          }

          .content-row {
            width: 365px;

            .auth-cards {
              max-width: 365px;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;

              &__card {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 20px 24px 18px;
                background: #FFFFFF;
                border: 1px solid #EFEFEF;
                box-sizing: border-box;
                box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.07);
                border-radius: 10px;
                cursor: pointer;
                transition: all ease .4s;
                margin-bottom: 7px;

                &:hover {
                  //transform: scale(1.1);
                  border: 1px solid #124D81;
                  box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.07);
                }

                .card-title {
                  font-weight: 500;
                  font-size: 24px;
                  color: #124D81;
                  margin-bottom: 0;
                }

                .card-icon {
                  width: 30px;
                  height: 30px;
                  margin-right: 16px;
                }

                .card-logo {
                  position: absolute;
                  bottom: 10px;
                  right: 10px;
                }
              }
            }

            .auth-content {

              text-align: center;
              font-weight: 300;
              font-size: 18px;
              color: #898B9D;

              a {
                color: #A0A6E1;
                text-decoration: underline;
              }
            }
          }
        }

        .content-footer {
          .buttons-col {
            display: flex;
            flex-direction: column;
            align-items: center;

            .button-rounded {
              width: fit-content;
              display: flex;
              align-items: center;
              border: 1px solid #EFEFEF;
              box-sizing: border-box;
              box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);
              border-radius: 26px;
              padding: 12px 10px;
              cursor: pointer;
              transition: all ease .4s;
              margin-bottom: 5px;
              font-size: 1rem;
              color: $primary;

              &:last-child {
                margin-bottom: 0 !important;
              }

              svg {
                margin-right: 8px;
              }


              &:hover {
                //transform: scale(1.1);
                border: 1px solid #124D81;
                box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.07);
              }
            }
          }
        }

        .footer {
          //display: flex;
          //justify-content: space-between;
          //width: 100%;

          position: absolute;
          bottom: -44px;
          right: 15px;
          z-index: -1;

          &-allrights {
            position: absolute;
            left: -880px;
            padding-left: 15px;
            margin-top: 20px;
          }


          &-contacts {
            display: flex;

            &__item {


              div {
                margin-left: 13px;

              }


              svg {


                path {
                  fill: #888A9C;
                }
              }

              a {
                min-width: 44px;
                min-height: 60px;
                cursor: pointer;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                font-weight: 500;
                font-size: 18px;
                color: #898B9D;
                padding: 8px;
                margin-right: 13px;
                background: #FFFFFF;
                box-shadow: 3px 3px 52px 6px rgba(0, 0, 0, 0.1);
                border-radius: 0px 0px 11px 11px;
                border: 1px solid #fff;
                transition: all ease-in .2s;
              }

              a.active {
                background: $primary;
                top: 10px;
                position: relative;

                svg {
                  margin-bottom: 6px;

                  path {
                    fill: #fff;
                  }
                }
              }

              .nav-link-footer {
                min-width: 166px;
                padding: 16px 20px 10px;

              }

              .nav-link-footer.active {
                display: flex;
                padding: 16px 20px 10px;
                align-items: center;
                color: white;
              }

              //&:hover {
              //  border-color: $primary;
              //
              //}
            }
          }
        }
      }
    }

    .note-inner.taller {
      height: 863px !important;
    }


  }

  &-wrapper.top {
    top: 16px !important;
  }

}


.notification-item {

  width: 345px;
  display: flex;
  padding: 8px;
  overflow-y: scroll;
  overflow-x: hidden;

  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  .item-title {
    font-weight: 400;
    font-size: 17px;
    color: #32323B;
    margin-right: auto;
  }

  .form-check-input {

    border: 1px solid #C4C4C4;

  }

  .form-check-input:checked {

  }

}

.app-container {
  max-width: 345px;
  width: 100%;
  height: 100%;
}

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;


  &-section-label {
    font-size: 16px;
    text-align: center;
    color: #898B9D;
    margin-bottom: 8px;

    span {
      color: #000000;
    }
  ;

  }

  &-title {
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    color: #32323B;
    margin-bottom: 2px;
    line-height: 38px;
  }

  &-desc {
    display: flex;
    justify-content: center;
    max-width: 335px;
    text-align: center;
  }

  &-sms-conntect {
    display: flex;
    flex-direction: column;
    align-items: center;

    div:nth-child(3) {
      button {
        width: 130px;
      }

      button:disabled {
        width: 130px !important;
      }
    }

    input {
      padding-left: 26px;
      padding-right: 26px;
      text-align: center;
      max-width: 256px;

      background: #FFFFFF;
      border: 1px solid #EFEFEF;
      box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
    }
  }

  &-sms-notifications {
    height: 290px;
    max-width: 355px;
    padding: 0px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    .notification-item {

      width: 345px;
      display: flex;
      padding: 8px;

      margin-bottom: 10px;

      background: #FFFFFF;
      border: 1px solid #EFEFEF;
      box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);
      border-radius: 10px;

      .item-title {
        font-weight: 400;
        font-size: 17px;
        color: #32323B;
        margin-right: auto;
      }


      .form-check-input {

        border: 1px solid #C4C4C4;

      }

      .form-check-input:checked {

      }

    }
  }

  &-slider {
    display: flex;
    width: 100%;
    max-width: 345px;
    align-items: center;

    span {
      font-size: 14px;
      color: #898B9D;
      margin-left: 4px;
      margin-right: 4px;
    }
  }

  &-sms-expire {
    width: 100%;
    padding: 0px;
    border: 2px solid #EFEFEF;
    box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);
    border-radius: 20px;

    .thumb {
      outline: none !important;
    }

    .thumb-0 {
      display: none;

      div {
        display: none;
      }
    }

    .thumb-1 {
      // background: #124D81;
      color: #000000;
      position: relative;
      z-index: 9999 !important;

      //&::before {
      //    z-index: 9;
      //    content: '';
      //    width: 8px;
      //    height: 8px;
      //    border-radius: 10px;
      //    background: #000;
      //    position: absolute;
      //    transform: translate(-50%, -50%);
      //    top: 160%;
      //    left: 52%;
      //}

      &::after {
        content: '';
        width: 23px;
        height: 23px;
        border-radius: 40px;
        background: #FFFFFF;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }

    }
  }

  &-report {
    &__info {
      text-align: center;
      font-size: 18px;
      color: #124D81;
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;

      .list-item {
        cursor: pointer;
        width: 345px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 12px;
        margin-bottom: 5px;
        border: 1px solid #EFEFEF;
        box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);
        border-radius: 10px;

        .checkbox-input {
          display: none;
          height: 24px;
          width: 24px;

          .lf-player-container {
            #lottie > svg > g > g:nth-child(4) > path {
              path {
                stroke: black !important;
              }
            }
          }
        }


        &__title {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 2px;
          color: #32323B;
        }

        &__price {
          font-size: 14px;
          line-height: 16px;
          color: #898B9D;
        }
      }
    }

    .langs-title {
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #898B9D;

    }

    &__langs {
      display: flex;
      justify-content: center;

      .langs-item {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        .checkbox-input {
          display: none;
          height: 24px;
          width: 24px;

          .lf-player-container {
            #lottie > svg > g > g:nth-child(4) > path {
              path {
                stroke: black !important;
              }
            }
          }
        }


        &__title {
          font-size: 18px;
          color: #32323B;
        }

        &__price {
          font-size: 14px;
          line-height: 16px;
          color: #898B9D;
        }
      }
    }
  }

  &-payments {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .page-payments__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      max-width: 345px;

      .list-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 162px;
        height: 119px;
        cursor: pointer;
        background: #FFFFFF;
        border: 1px solid #E7E7E7;
        box-shadow: 3px 3px 52px 6px rgba(0, 0, 0, 0.05);
        border-radius: 21px;
        margin-bottom: 6px;
      }
    }
  }

  &-esp {
    overflow: scroll;

    .page-esp__list {
      height: 530px;
      overflow-y: scroll !important;

      .list-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 14px 22px;
        background: #FFFFFF;
        border: 1px solid #EFEFEF;
        box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        color: #32323B;
        width: 345px;
        margin-bottom: 8px;

        &__info {
          display: flex;
          justify-content: space-between;

          .info {

            span {
              font-size: 12px;
              color: #898B9D;
            }
          }
        }
      }
    }
  }

  .page-action {
    width: 100%;
    max-width: 345px;

    button {
      font-size: 1.4rem !important;
    }
  }
}

.full-layout {
  &__title {
    text-align: center;
    font-size: 2rem;
    text-align: center;
    font-weight: 700;
    color: #32323B;
  }

  .history {
    &-tabs {
      display: flex;
      justify-content: stretch;

      &__button {
        font-size: 1.3rem;
        font-weight: 500;
        color: #898B9D;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-grow: 1;
        padding: 12px;
        box-shadow: 0px 3px 30px 2px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background: white;
        transition: all ease-in-out .2s;
        margin-right: 12px;

        &.active {
          background: $primary;
          color: white;
        }

        &.disabled {
          background: #e3e3e3;
          color: white;
        }
      }
    }

    &-tables {
      background: #FFFFFF;
      box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      padding: 4px;
      padding-top: 40px !important;
      max-height: 540px;

      &__wrapper {
        overflow-y: scroll;
        height: 515px;
        position: relative;
        padding-right: 14px;
        width: 1060px;


        .table-header-bg {
          background: red;
          width: 83.8%;
          height: 38px;
          top: 220.9px;
          position: fixed;
          box-sizing: border-box;
          border-radius: 5px 5px 0px 0px;
          border-bottom: 1px solid #ebe9f1;
          background: #F9FAFB;
          //width: 100%;
          //height: 37.3px;
          //background: red;
          ////background: #F9FAFB;
          //position: absolute;
          //top: -33px;
          //// transform: translate(100%, 2px);
          //border-radius: 5px 5px 0px 0px;
          //border-bottom: 1px solid #ebe9f1;
        }
      }


      .ReactTable .rt-thead {

      }


      .table-responsive {
        margin-top: 10px;
      }

      table {
        tbody {
          tr {
            td:first-child {
              text-align: left;
            }

            td {
              text-align: center;
            }
          }
        }

        thead {
          position: fixed;
          top: 220px;
          width: 1046px;
          background: #f9fafb;


          tr {
            th {
              padding-left: 0;
              padding-right: 0;

              //position: sticky;
              //top: 0;
              //font-size: 18px;
              //font-weight: 500;
              //color: #898B9D;
              //text-transform: none;
              background: #F9FAFB !important;
            }
          }
        }
      }

      table > tbody > tr > td {
        padding-left: 0;
        padding-right: 0;

        font-size: 18px;
        color: #898B9D;
      }

      table > tbody > tr > td:nth-child(1) {
        button {
          padding: 6px 12px;
          min-width: 90px;
          font-size: 1rem;
        }

        button:first-child {
          margin-right: 10px !important;
        }
      }
    }
  }

  .news {
    display: grid;
    grid-template-columns: 4.5fr 7.5fr;

    &-list {
      overflow-y: scroll;
      height: 580px;
      padding-top: 40px;
      padding-right: 20px;

      &__item {
        position: relative;
        cursor: pointer;

        img {
          position: absolute;
          max-height: 153px;
          max-width: 153px;
          border-radius: 16px;
          top: -40px;
          left: 12px;
          z-index: 2;
        }

        .content {
          padding: 14px 0;
          padding-right: 10px;
          display: flex;
          border-radius: 10px;
          box-shadow: 3px 3px 29px 6px rgba(0, 0, 0, 0.05);

          span {
            min-width: 180px;
          }

          div {
            .content-title {
              font-size: 14px;
              color: #030229;
            }

            .content-date {
              font-size: 12px;
              color: #B3B3BF;
            }
          }
        }
      }

      &__item.active {
        .content {
          background: $primary;

          div {
            .content-title {
              color: #fff;
            }

            .content-date {
              color: #E2E2E2;
            }
          }
        }
      }
    }

    &-content {
      overflow-y: scroll;
      height: 580px;
      padding-left: 20px;
      padding-right: 10px;

      &__banner {
        padding-left: 30px;


        img {
          height: 310px;
          object-fit: cover;
          max-width: 550px;
          border-radius: 10px 10px 0px 0px;
          margin-bottom: 12px;
        }

        .banner-title {
          position: relative;
          font-size: 24px;
          color: #030229;
          max-width: 482px;
          padding-left: 32px;
          min-height: 76px;

          &:before {
            content: '';
            position: absolute;
            left: 10px;
            width: 3px;
            height: 77px;
            background: $primary;
            border-radius: 10px;
          }
        }
      }

      &__text {
        padding-left: 20px;
      }
    }
  }

  .support {

    .contacts {

      h2 {
        font-size: 28px;
      }

      .contacts-item {
        display: flex;
        align-items: center;

        h4 {
          margin-bottom: 0;
        }

        svg {
          margin-right: 10px;
        }
      }
    }

    &-tabs {
      display: flex;
      justify-content: stretch;
      max-width: 371px;

      &__button {
        font-size: 1rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-grow: 1;
        padding: 12px;
        box-shadow: 0px 3px 30px 2px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background: white;
        transition: all ease-in-out .2s;
        margin-right: 12px;

        &.active {
          background: $primary;
          color: white;
        }
      }
    }

    .faqs {
      overflow: hidden;

      .accordion {
        height: 600px;
        overflow-y: scroll;

        &-item {
          border: none;
          //border-radius: 10px !important;
          //box-shadow: 3px 3px 29px 6px rgba(0, 0, 0, 0.08) !important;


          .accordion-header {
            border-radius: 10px !important;
            position: relative;
            padding-left: 25px;
            //&:before {
            //  position: absolute;
            //  content: '';
            //  width: 7px;
            //  height: 7px;
            //  border-radius: 10px;
            //  background: #000 !important;
            //  z-index: 4 !important;
            //  top: 20px;
            //  left: 20px;
            //}
          }


          .accordion-button::after {
            background: none;

          }


          .accordion-collapse {
            .accordion-body {
              text-align: justify !important;
              padding-left: 50px;
              color: #898B9D;
            }
          }
        }
      }
    }

    .contacts {

      &-item {
        margin-bottom: 20px;

        &.icons {
          display: flex;
          margin-top: auto;
          margin-bottom: 0;

          a {
            margin-right: 15px;
          }
        }

        span {
          margin-bottom: 4px;
          color: #898B9D;
        }

        h4 {
          font-size: 24px;
          font-weight: bold;
          color: #898B9D;
        }
      }

      &-content {
        font-size: 16px;
        color: #898B9D;
      }

      &-row {
        max-width: 680px;
        display: flex;
        justify-content: space-between;

        &__item {
          font-size: 16px;
          color: #898B9D;


          span {

          }

          div {

          }
        }
      }

      &-map {
        &__address {
          font-size: 16px;
          color: #898B9D;

          span {

          }

          div {

          }
        }

        &__iframe {

        }
      }
    }
  }

  &__col {

    Button {
      svg {
        margin-right: 18px;
      }
    }

    .user-info {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 3px 30px 2px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      padding: 0;
      // max-height: 540px;

      overflow-y: hidden;
      margin-bottom: 0;

      li {
        padding: 10px 20px;
        width: 100%;
        display: flex;
        align-items: center;
        list-style: none;
        border-bottom: 1px solid #E5E7EB;

        span {
          min-width: 200px !important;
        }


        &:nth-child(12) {
          border-bottom: none;

          div {
            display: flex;
            flex-direction: row;

            input {
              margin-right: 10px;
            }
          }
        }

        &:last-child {
          border-bottom: none;

          div {
            display: flex;
            flex-direction: row;

            input {
              margin-right: 10px;
            }
          }
        }

        span {
          margin-right: 30px;
          width: 180px;
          font-size: 16px;
          color: #898B9D;
        }

        div {
          font-size: 16px;
          color: #32323B;

          .user-input {
            padding: 0 10px;
            font-size: 19px;
            height: 10px;
            background: #F4F5F8;
          }
        }
      }
    }

    .user-photo {
      width: 210px;

      .user-img {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 203px;
        background: #FFFFFF;
        box-shadow: 0px 3px 30px 2px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        // margin-top: 46px;
        margin-bottom: 6px;

        #fileButton {
          position: absolute !important;
          left: 0;
          height: 100%;
          width: 88%;
          cursor: pointer;
          font-size: 0;
          opacity: 0;
        }

        img {
          cursor: pointer;
          border-radius: 5px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

      }
    }
  }


}

.comment-buttons {
  width: 362px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6px;

  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}


.comment-add {

  .popover {
    .arrow {
      display: none !important;
    }
  }

  div {
    width: 400px !important;
    background: #FFFFFF !important;
    border-radius: 15px !important;
    min-width: 400px !important;
    ///box-shadow: 3px 3px 52px 6px rgba(0, 0, 0, 0.1);

    .buttons {
      width: 234px;
      display: flex;
      justify-content: space-between;

      button {
        width: 110px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.page-comments {
  height: 100%;
  overflow-x: hidden;
  padding-right: 14px;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #FAF7F7;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #C4C4C4;
  }

  &__item {
    min-width: 345px;
    padding: 14px 12px;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 10px;
    margin-bottom: 12px;

    .item-header {
      display: flex;
      justify-content: space-between;
    }

    .item-body {
    }
  }
}

@media only screen and (max-width: 1240px) {

  .logout {
    display: none;
  }

  .history {
    height: 100% !important;
  }

  .note-inner {
    background: none !important;
    box-shadow: none !important;

    &__content {
      background: none !important;
      box-shadow: none !important;
    }
  }

  .footer {
    display: none;
  }
  .note {
    &-wrapper {
      width: 100%;

      .note-inner {

        &__dots {
          display: none;

          .dot {

            .rect {
            }

            .rect:first-child {
            }

            .line {
            }
          }
        }

        &__banner {
          display: none;
        }

        &__content {


          .content-header {


            &__langs {


              .lang {


                &:last-child {
                }
              }
            }
          }

          .content-body {

            .content-logo {
            }

            .content-headings {
            }

            .content-row {
              .auth-cards {

                &__card {

                  &:hover {
                  }

                  .card-title {
                  }

                  .card-icon {
                  }
                }
              }

              .auth-content {
              }
            }
          }

          .content-footer {
            .buttons-col {

              .button-rounded {

                &:last-child {
                }

              }
            }
          }

          .footer {

            &-contacts {

              &__item {
              }
            }
          }
        }
      }
    }
  }

  .history {
    &-tabs {
      &__button {
        &.active {
        }
      }
    }

    &-tables {
      &__wrapper {
        .table-header-bg {
          top: 220px !important;
        }
      }

      .ReactTable .rt-thead {
      }

      .table-responsive {
      }

      table {
        tbody {
          tr {
            td:first-child {

            }

            td {

            }
          }
        }

        thead {
          top: 220px !important;

          tr {
            th {

            }
          }
        }
      }

      table > tbody > tr > td:nth-child(1) {
        button {

        }

        button:first-child {

        }
      }
    }
  }
}

@media only screen and (max-width: 1205px) {


  .accordion-button {
    font-size: 12px !important;
  }

  .mobile-header-menu {
    display: flex;
  }

  .news-list__item.active {
    .content {
      background: #fff !important;

      .content-title {
        color: #030229 !important;
      }
    }
  }

  .news {
    display: grid;
    grid-template-columns: 1fr !important;

    .news-list {
      padding: 40px 0 0 0;

      .news-list__item.active {
        background: #fff !important;
      }
    }

    .news-content {
      display: none;
    }
  }

  .scrollbar-container.main-menu-content.ps > ul > li > a > svg {
    width: 32px;
    height: 32px;

    path {
      fill: #898B9D;
    }
  }

  .content-header .content-header__langs {
    display: none !important;
  }

  .note-inner__content {
    #header .content-header__langs {
      display: flex !important;
    }
  }

  .user-name-ds {
    display: none !important;
  }
  .user-name-mb {
    display: block;
  }

  .user-nav {
    grid-area: header;
    display: flex;
    justify-content: center;
    margin-bottom: 20px !important;
  }
  .bookmark-wrapper {
    grid-area: main;
  }
  .navbar-nav {
    grid-area: main;
  }

  .mobile-nav {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    "header header header"
    "main main main";
  }

  .full-layout {
    &__title {

    }

    .history {
      height: 75vh;
      overflow-y: scroll;

      &-tabs {
        flex-direction: column;

        &__button {
          margin-bottom: 5px;

          &.active {

          }
        }
      }

      &-tables {
        background: white !important;
        box-shadow: none;
        padding-top: 10px !important;
        overflow: scroll !important;

        table {
          tbody {
            tr {
              td:first-child {

              }

              td {

              }
            }
          }

          thead {

            tr {
              th {

              }
            }
          }
        }

        table > tbody > tr > td:nth-child(1) {
          text-align: center;

          button {

          }

          button:first-child {
            margin-bottom: 4px;
            margin-right: 0 !important;
          }
        }

        table > tbody > tr > td:nth-child(2) {
          // min-width: 150px;
        }
      }
    }

    &__col {

      Button {
        svg {

        }
      }

      .user-info {

        li {

          &:last-child {

          }

          span {

          }

          div {

          }
        }
      }

      .user-photo {
        .user-img {
        }
      }
    }
  }

}

.reportPop {
  display: grid;
  grid-gap: 5px;

  div {
    span {
      color: #ADA7A7;
    }
  }
}

.mobile {
  &-list-login {
    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #E5E7EB;
      font-size: 18px;
    }
  }

  &-list-payment {
    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #E5E7EB;
      font-size: 18px;
    }
  }

  &-list-sms {
    &__item {
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      padding: 14px 19px;
      margin-bottom: 10px;
      font-size: 18px;

      :first-child {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;
      }
    }
  }

  &-list-reports {
    &__item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 10px 18px;
      margin-bottom: 5px;

      background: #FFFFFF;
      box-shadow: 3px 3px 30px 2px rgba(0, 0, 0, 0.05);
      border-radius: 5px;

      .item-title {
        font-size: 16px;
        color: #C4C4C4;
        margin-bottom: 2px;
      }

      .item-value {
        font-size: 18px;
        color: #898B9D;
        margin-bottom: 2px;
      }

      .item-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;

        button:first-child {
          margin-right: 10px !important;
        }
      }

    }
  }
}

@media only screen and (max-width: 920px) {


  .accordion {
    height: 100% !important;

    .accordion-label {
      max-width: 306px !important;
      font-size: 18px;
      font-weight: 400;
    }

  }
  .wrapper {
    padding-left: 6px;


    .header-navbar {
      left: 0;
    }
  }

  .history-tables {
    background: none !important;
    box-shadow: none !important;

    .wrapper {
      .table-header-bg {
        display: none;
      }
    }
  }

  .user-info {
    li {


      &:last-child {

      }

      span {
        min-width: 150px !important;
        margin-right: 20px !important;
      }

      div {

      }
    }
  }
}


@media only screen and (max-width: 860px) {

  .user-info {
    height: 550px;

    overflow-y: scroll;

    li {
      flex-direction: column;
      align-items: start !important;

      &:last-child {

      }

      &:nth-child(5) {
        div > div {
          flex-direction: column;
        }
      }

      span {

      }

      div {

      }
    }
  }
}

@media only screen and (max-width: 840px) {
  .note {
    &-wrapper {
      position: relative;

      .note-inner {
        height: 100%;

        .app-content {
          height: 100% !important;
          overflow: visible !important;
        }
      }
    }
  }
}


@media only screen and (max-width: 640px) {


  .cswitch {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .note-inner {
    height: 100% !important;
    overflow: visible !important;

    &__content {
      height: 100% !important;
      overflow: visible !important;
    }
  }

  .page {
    //padding-top: 60px;
    height: 100%;
    overflow: visible !important;
  }

  .mobile-nav {
    background: #fff !important;
  }

  .page-title {
    margin-top: 0px !important;
  }

  .full-layout__title {
    margin-top: 60px;
  }

  .page-report__list {
    width: 345px;
    align-items: flex-end !important;

    .item-wrapper {
      position: relative;
      width: 310px;
      display: flex;
      align-items: center;

      .icon-reports {
      
      }
    }

  }


  .news-content__banner {
    img {
      height: 206px !important;
    }
  }

  .container {
    max-width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .container-xxl {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .note {
    &-wrapper {

      position: unset;
      transform: none;
      height: 96vh;
      overflow-x: hidden;
      overflow-y: scroll;
      width: 100%;
      padding-top: 0 !important;

      .note-inner {
        position: unset;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background: none;
        box-shadow: none;

        &__dots {
          display: none;

          .dot {
            .rect {
            }

            .rect:first-child {
            }

            .line {
            }
          }
        }

        &__banner {
          display: none;
        }

        &__content {
          position: unset;
          width: 100%;
          padding: 40px 8px;
          background: none;
          box-shadow: none;
          padding-bottom: 80px;

          .app-content {
            .container-xxl {
              .page {
                &-title {
                  margin-top: 2px;
                }
              }
            }
          }

          .content-header {


            &__langs {


              .lang {


                &:last-child {
                }
              }
            }
          }

          .content-body {

            .content-logo {
            }

            .content-headings {
            }

            .content-row {
              width: unset;

              .auth-cards {

                &__card {


                  &:hover {
                  }

                  .card-title {

                  }

                  .card-icon {

                  }

                  .card-logo {

                  }
                }
              }

              .auth-content {
              }
            }
          }

          .content-footer {
            .buttons-col {

              .button-rounded {

                &:last-child {
                }

              }
            }
          }

          .footer {
            display: none;

            &-contacts {

              &__item {
              }
            }
          }
        }

        .user-photo {
          margin: 8px auto;
          margin-bottom: 0 !important;
          display: flex;
          justify-content: center;

          div {
            width: 181px;
          }

          .user-img {
            margin: 0 auto;
          }
        }

        .user-info {
          height: 854px;
          margin-bottom: 60px;

          &.active {
            height: 920px !important;
            max-height: 920px !important;
          }

          li {
            flex-direction: column;

            span {
              width: 100%;
            }
          }

          li:last-child {
            div {
              flex-direction: column;

              input {
                width: 100% !important;
                margin-bottom: 8px;
              }
            }
          }

        }

        .user-info-mob {
          display: flex;
          flex-direction: column-reverse;

        }
      }
    }
  }
}

.modern-nav-toggle {
  padding: 4px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.07);
}

.popover {
  z-index: 9999 !important;
}

@media only screen and (max-width: 540px) {

  .page-comments {
    overflow-y: hidden !important;
    height: fit-content !important;
  }

  .main-menu.menu-fixed {
    z-index: 999999;
  }

  #root {
    .wrapper {
      overflow: visible !important;
      padding: 0;


      .header-navbar.navbar-fixed {
        position: static;
        z-index: 9999;

        .mobile-nav {
          z-index: 9999 !important;
        }
      }

      .note-wrapper {
        height: 100%;
        overflow: visible !important;


        .full-layout__title {
          margin-top: 0;
        }

        .history {
          height: 100%;
          overflow: visible !important;

          .history-tables {
            height: 100%;
            overflow: visible !important;

          }
        }
      }
    }
  }

}

@media only screen and (max-width: 365px) {


  .note {
    &-wrapper {
      position: unset;
      transform: none;
      height: 100vh;
      width: 100%;

      .note-inner {
        position: unset;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100vh;

        &__dots {
          display: none;

          .dot {
            .rect {
            }

            .rect:first-child {
            }

            .line {
            }
          }
        }

        &__banner {
          display: none;
        }

        &__content {
          position: unset;
          width: 100%;
          padding: 10px;

          .content-header {


            &__langs {


              .lang {


                &:last-child {
                }
              }
            }
          }

          .content-body {

            .content-logo {
            }

            .content-headings {
            }

            .content-row {
              width: unset;

              .auth-cards {
                justify-content: center;

                &__card {


                  &:hover {
                  }

                  .card-title {
                  }

                  .card-icon {
                  }
                }
              }

              .auth-content {
              }
            }
          }

          .content-footer {
            .buttons-col {

              .button-rounded {

                &:last-child {
                }

              }
            }
          }

          .footer {
            display: none;

            &-contacts {

              &__item {
              }
            }
          }
        }
      }
    }
  }

  .page-payments .page-payments__list {
    grid-template-columns: 1fr;
  }
}


@media screen and (max-height: 950px) {
  .wrapper {
    overflow: scroll !important;
    height: fit-content !important;
  }
}


@media screen and (max-height: 926px) {
  .note-wrapper {
    max-height: 90vh !important;

    .note-inner {
      max-height: 90vh !important;
    }
  }
}

.list-item__rdate {
  margin-top: 11px;
  color: #124D81;
  font-style: italic;
  font-size: 12px;
}

#react-select-4-listbox {
  position: absolute;
  z-index: 99999999999999 !important;
}

.freeze_info {
  max-width: 345px;
  width: inherit;
  display: flex;
  flex-direction: column;

  .freeze_info__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .value {
        color: #1e1e1e;
    }
  }
}