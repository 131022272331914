$primary: #124D81; // Changing primary color
$body-bg: #ffff;

.text-grey {
  color: #898B9D;
}

.text-dark {
  color: #32323B;
}


